<template>
    <div class="wordmsg">
        <full-scroll></full-scroll>
        <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
            <div class="flex flex-pack-center flex-1">
                <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">
                    {{ $route.query.sourceType == 2 ? '民生实事' : $route.query.sourceType == 1 ? '连心桥' :
                        $route.query.sourceType ==
                            4 ? '许心愿' : $route.query.sourceType == 7 ? '立法意见留言' : '' }}
                </div>
            </div>
            <div class="flex-1 flex-end flex" @click="backHome">
                <div class="my_text_center">
                    <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="video-msg">
                <p class="font-size24 font-blod m_b5 color_FF my_text_center">
                    请填写以下信息
                </p>

                <div class="flex flex-justify-center m_b5" :class="$route.query.sourceType == 2
                    ? 'flex-justify-center'
                    : ''
                    ">
                    <div class="">
                        <div class="
                                word-input-box
                                font-size22
                                flex flex-pack-center
                                m_b5
                            ">
                            <van-field v-model="realName" label="真实姓名" label-width="6em" placeholder="请输入您的真实姓名"
                                class="input-s" />
                        </div>
                        <div class="
                                word-input-box
                                font-size22
                                flex flex-pack-center m_b5
                            ">
                            <van-field v-model="contactNum" @input="validateInput" @blur="validate" type="tel"
                                maxlength="11" label-width="6em" label="联系电话" placeholder="请输入联系电话" class="input-s" />
                        </div>
                        <!-- <p class="input-tips m_b5" >
                            手机号可以用来快速回访，解决问题
                        </p> -->

                        <div class="font-size22 flex">
                            <van-field v-model="content" type="textarea" maxlength="100" label="许愿内容" label-width="6em"
                                placeholder="请输入许愿内容(100字以内)" class="word-textarea"
                                v-if="$route.query.sourceType == 4" />
                            <van-field v-model="content" type="textarea" maxlength="100" label="留言内容" label-width="6em"
                                placeholder="请输入留言内容(100字以内)" class="word-textarea" v-else />

                        </div>
                    </div>
                    <div class="
                            input-radio-box
                            flex flex-pack-center
                            font-size22
                            color_FF
                        " v-if="$route.query.sourceType == 2">
                        <div class="input-radio-box-inner">
                            <div class="input-radio-title m_b30">留言类型</div>
                            <div>
                                <van-radio-group v-model="radio" direction="horizontal">
                                    <van-radio @click="changeInputRadio(item)" :name="index" class="m_b30 m_r20"
                                        checked-color="red" v-for="(item, index) in dicItemScreem" :key="index">{{
                                            item.label }}</van-radio>
                                </van-radio-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my_text_center flex flex-justify-center">
                    <div class="input-submit" v-if="$route.query.sourceType == 2" @click="postWord">立即提交</div>
                    <div class="input-submits" v-else @click="postWord">立即提交</div>
                </div>


            </div>
        </div>

        <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
            <div class="my_text_center" @click="goBack">
                <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF"></div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { dateTimeFormat } from "@/request/until.js";
import { wordTopic, getDicScreen, wishApplicationAdd, CommentsSubmitComments } from "@/request/api.js";
import { Toast } from "vant";
import fullScroll from "@/components/fullScroll.vue";
export default {
    components: { fullScroll },
    data() {
        return {
            todayMtime: "",
            todayTime: "",
            realName: "",
            contactNum: "",
            content: "",
            radio: "1",
            style: null,
            dicItemScreem: [],
            sourceType: null,
            tnum: ''//立法意见传的id
        };
    },
    mounted() {
        this.sourceType = Number(this.$route.query.sourceType);
        if (this.$route.query.sourceType == 2) {
            this.getDicScreenList();
        }
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },
    created() {
        this.getTime();
        this.tnum = this.$route.query.tnum;
    },
    methods: {

        validateInput() {
            if (/\D/g.test(this.contactNum)) {
                Toast.fail('请输入数字');
                this.contactNum = '';
            }
            if (this.contactNum.charAt(0) !== '1') {
                Toast.fail('请输入以数字1开头的手机号码');
                this.contactNum = '';
            }
        },
        validate() {
            if (this.contactNum.length < 11) {
                Toast.fail('请输入11位手机号');
            }
        },

        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        //提交
        postWord() {
            if (this.contactNum.length < 11) {
                Toast.fail('请输入11位手机号');
            } else {
                if (this.$route.query.sourceType == 1) {
                    this.style = 0;
                    wordTopic({
                        realName: this.realName,
                        contactNum: this.contactNum,
                        content: this.content,
                        style: this.style,
                        sourceType: this.sourceType,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                Toast.success(res.message);
                                setTimeout(() => {
                                    this.todayMtime = "";
                                    this.todayTime = "";
                                    this.realName = "";
                                    this.contactNum = "";
                                    this.content = "";
                                    this.radio = "1";
                                    this.style = null;
                                }, 1000);
                            } else {
                                Toast.fail(res.message);
                            }
                        })
                        .catch((err) => {
                            Toast.fail(err.message);
                        });
                } else if (this.$route.query.sourceType == 2) {
                    wordTopic({
                        realName: this.realName,
                        contactNum: this.contactNum,
                        content: this.content,
                        style: this.style,
                        sourceType: this.sourceType,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                Toast.success(res.message);
                                setTimeout(() => {
                                    this.todayMtime = "";
                                    this.todayTime = "";
                                    this.realName = "";
                                    this.contactNum = "";
                                    this.content = "";
                                    this.radio = "1";
                                    this.style = null;
                                }, 1000);
                            } else {
                                Toast.fail(res.message);
                            }
                        })
                        .catch((err) => {
                            Toast.fail(err.message);
                        });
                } else if (this.$route.query.sourceType == 4) {
                    wishApplicationAdd({
                        realName: this.realName,
                        phone: this.contactNum,
                        content: this.content,
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                Toast.success("许心愿成功，等待审核");
                                setTimeout(() => {
                                    this.todayMtime = "";
                                    this.todayTime = "";
                                    this.realName = "";
                                    this.contactNum = "";
                                    this.content = "";
                                    this.radio = "1";
                                    this.style = null;
                                    this.$router.back();
                                }, 1000);

                            } else {
                                Toast.fail(res.message);
                            }
                        })
                        .catch((err) => {
                            Toast.fail(err.message);
                        });
                } else {
                    CommentsSubmitComments({
                        realName: this.realName,
                        phone: this.contactNum,
                        content: this.content,
                        id: this.tnum
                    })
                        .then((res) => {
                            if (res.code == 200) {
                                Toast.success(res.message);
                                setTimeout(() => {
                                    this.todayMtime = "";
                                    this.todayTime = "";
                                    this.realName = "";
                                    this.contactNum = "";
                                    this.content = "";
                                    this.radio = "1";
                                    this.style = null;
                                    sessionStorage.setItem('hasNew',true)
                                    this.$router.back();
                                }, 1000);
                            } else {
                                Toast.fail(res.message);
                            }
                        })
                        .catch((err) => {
                            Toast.fail(err.message);
                        });
                }
            }

        },

        //拿到上传类别下拉筛选
        getDicScreenList() {
            getDicScreen({ code: "feedback_style" }).then((res) => {
                this.dicItemScreem = res.data;
            });
        },

        changeInputRadio(item) {
            this.style = item.id;
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },

        goBack() {
            this.$router.back();
        },
    },
};
</script>
